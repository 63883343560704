import img from 'assets/images/services-ereputation-1.jpg';
import { Container, Grid, GridColumn, Image } from 'semantic-ui-react';

const ServiceSubPage = () => {
  return (
    <Container>
      <Grid padded columns="2">
        <GridColumn verticalAlign="bottom" textAlign="right" mobile={16} largeScreen={8}>
          <Image centered src={img} />
        </GridColumn>
        <GridColumn mobile={16} largeScreen={8}>
          <h1 className="uppercase sub-border" style={{ margin: 0 }}>
            Notre Mission
          </h1>
          <p className="justify">
            Que vous soyez un établissement hôtelier, un restaurant, une régie immobilière, une école, un salon de coiffure ou cabinet dentaire; vous avez tous besoin de contrôler votre réputation en ligne (e-reputation). Aujourd’hui, un consommateur fait en moyenne 5 comparaisons avant de faire son choix. Il va faire attention aux commentaires sur Facebook, Google et autres plateformes de référencement d’opinions. Il comparera votre ranking et celui de vos concurrents. C'est pour cela qu'à
            Trade For World, agence de stratégies web, nous avons développé des processus d'analyses, et veille qui nous permettent de suivre et d'optimiser votre image de marque sur internet.
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase"> Analyse de votre présence sur internet </h2>
          <p className="justify">
            L'analyse est la première phase du processus de management de votre réputation . Dans cette étape nous réalisons une étude poussée de votre image de marque et de votre réputation. Leurs impacts sur vos outils digitaux (site internet, blog, réseaux sociaux...), sur vos clients actuels et sur l’intégralité d'internet. Nous vérifions l'adéquation entre votre image de marque sur le web et les spécificités de votre entreprise (ses valeurs, sa mission, sa vision). Nous identifions qui sont
            vos ambassadeurs et vos détracteurs. Nous vous comparons à vos principaux concurrents dans le but d'établir une matrice de performance (ton of voice).
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">Mise en place de votre veille marketing </h2>
          <p className="justify">
            Suite à l’analyse de votre image de marque, nous mettons en place un processus de veille marketing qui permettra de garder une visibilité constante de votre réputation et de la qualité de votre image de marque sur l'ensemble du web, des réseaux sociaux et de plateformes de recommandations. Nous créons des rapports réguliers et des alertes si en cas de commentaire négatifs ou baisse de votre e-reputation. Vous recevez ces informations directement par email et nous mettons à votre
            disposition des tableaux de contrôles dynamiques afin que vous puissiez suivre vos résultats 24/24.
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">Optimisation</h2>
          <p className="justify">Nous analysons fréquemment les résultats obtenus afin de déceler de zone d'amélioration quant à votre réputation sur internet. Nous vous faisons des recommandations quant aux services ou produits à développer afin de booster votre image de marque et réputation. Ensemble, nous décidons de nouveaux processus et/ou outils à mettre en place afin d'optimiser votre image de marque.</p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">Suivi</h2>
          <p className="justify">Tout au long de notre mandat, nous vous assurons un suivi régulier de vos résultats. A la fin de notre mandat, nous vous garantissons un accès aux différents tableaux de bord dynamiques et rapports moyennant un forfait mensuel réduits.</p>
        </GridColumn>
      </Grid>
    </Container>
  );
};

export default ServiceSubPage;
