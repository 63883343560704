import imgInbound from 'assets/images/inbound-img-1.jpg';
import PagesHero from 'components/PagesHero';
import APPContext from 'config/context/APPContext';
import React from 'react';
import { Container, Divider, Grid, GridColumn, Image, List } from 'semantic-ui-react';
const MarketingInbound = (props) => {
  const context = React.useContext(APPContext);
  React.useEffect(() => {
    context.setHero({ hero: <PagesHero title="" className="inbound" />, activeMenu: '/marketing-inbound' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="inbound">
      <section className="block">
        <div className="header">
          <h1>
            Cabinet de conseils en stratégies digitales <br /> spécialisé dans le marketing Inbound.
          </h1>
        </div>
        <div className="content">
          <p className="justify">Une stratégie Inbound est une "relativement" nouvelle méthodologie de communication et commercialisation sur internet. Les stratégies Inbound sont apparues avec le développement d'internet qui donne de plus en plus d'importance aux consommateurs. A EZI Marketing, nous avons décidé d'orienter notre approche de marketing digital sur cette méthodologie. Nous résumons cette décision en une seule phrase : "LE CLIENT EST R O I !"</p>
          <Image src={imgInbound} />
        </div>
      </section>
      <Divider />
      <section className="block">
        <div className="header color-teal">
          <h1>Le marketing Inbound est une discipline très vaste faisant appel à de nombreux outils techniques et méthodes. Voici une liste non exhaustive regroupant certaines pratiques, canaux et outils propres aux stratégies Inbound :</h1>
        </div>
        <div className="content">
          <List>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Audit de performances de vos outils digitaux (site web, réseaux sociaux, blogs...)</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Analyse de data (data mining), intelligence marketing</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Segmentation et profiling</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>étude de votre image de marque en ligne via des outils d’e-réputation et une veille marketing du earned média.</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>La définition de votre visibilité, notoriété, interactivité sur internet.</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Les stratégies de référencement et de positionnement en ligne (ou SEO)</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Les stratégies payantes via Google Ad Facebook, Instagram, YouTube, LinkedIn Advertising (SEA - SMA)</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Lead management (lead Gen), Lead Nurturing</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Audit et création de contenu</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Gestion de communauté en ligne</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Automatisation de processus en ligne (marketing automation)</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Création, gestion et optimisation de contenu pour réseaux sociaux, blogs, site internet, mail...</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="dot circle" />
              <List.Content>Création, mise en place et suivi de stratégies de marketing en ligne</List.Content>
            </List.Item>
          </List>
        </div>
      </section>
      <Divider />
      <section className="block">
        <div className="header">
          <h1>
            Nous avons regroupé l'ensemble de ces différents points en 4 services <br /> que nous proposons à nos clients:
          </h1>
        </div>
        <div className="content bottom">
          <Grid>
            <GridColumn mobile={16} largeScreen={8} textAlign="left" verticalAlign="top">
              <List>
                <List.Item>
                  <List.Icon name="check circle" color="teal" size="big" />
                  <List.Content>Le marketing de contenu (content marketing)</List.Content>
                </List.Item>
              </List>
            </GridColumn>
            <GridColumn mobile={16} largeScreen={8} textAlign="left" verticalAlign="top">
              <List>
                <List.Item>
                  <List.Icon name="check circle" color="teal" size="big" />
                  <List.Content>Le consulting digital</List.Content>
                </List.Item>
              </List>
            </GridColumn>
            <GridColumn mobile={16} largeScreen={8} textAlign="left" verticalAlign="top">
              <List>
                <List.Item>
                  <List.Icon name="check circle" color="teal" size="big" />
                  <List.Content>La création de stratégies digitales</List.Content>
                </List.Item>
              </List>
            </GridColumn>
            <GridColumn mobile={16} largeScreen={8} textAlign="left" verticalAlign="top">
              <List>
                <List.Item>
                  <List.Icon name="check circle" color="teal" size="big" />
                  <List.Content>Le management de réputation et d'image de marque sur internet (e-reputation, e-branding)</List.Content>
                </List.Item>
              </List>
            </GridColumn>
          </Grid>
        </div>
      </section>
    </Container>
  );
};

export default MarketingInbound;
