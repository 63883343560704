import React from 'react';
import { Container, Grid, GridColumn, Image, List } from 'semantic-ui-react';
import img from 'assets/images/services-consulting-1.jpg';

const ServiceSubPage = () => {
  return (
    <Container>
      <Grid padded columns="2">
        <GridColumn mobile={16} largeScreen={8}>
          <h1 className="uppercase sub-border" style={{ margin: 0 }}>
            Notre Mission
          </h1>
          <p className="justify">
            Si vous souhaitez des conseils quant à votre stratégie de marketing digital ou si vous avez besoins d’analyses concernant votre image de marque, la performance de vos actions en ligne, l'implémentation de nouveaux outils (CRM, ERP...). Trade For World vous accompagne dans l’ensemble de ces actions et vous apporte son expertise. Nous vous aidons à mieux comprendre et connaitre votre entreprise d'un point de vue digital. Nous vous coachons afin de vous permettre d'être mieux préparé à
            comprendre les différents processus et comportement de l'ensemble de vos parties prenantes.{' '}
          </p>
        </GridColumn>
        <GridColumn verticalAlign="bottom" textAlign="right" mobile={16} largeScreen={8}>
          <Image centered src={img} />
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase"> Gestion de projets digitale</h2>
          <p className="justify">Si vous travaillez sur un projet lié au marketing digital (lancement d’un nouveau site internet, création d’application, lancement de campagne en ligne etc…). EZI Marketing est là pour vous accompagner et vous conseillez selon vos besoins et vos attentes.</p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">Intelligence Marketing </h2>
          <p className="justify">A l'ère du digital et des nouvelles technologies, l'utilisation de vos données est devenu un enjeux cruciales du succès de votre PME. Et ceci indépendamment de votre taille. C'est pour cela qu'EZI Marketing vous proposons des outils qui optimise votre stratégie digitale grâce à un scanning de votre entreprise sur internet: </p>
          <List bulleted>
            <List.Item>Outils d'alerte, nous scannons le web afin de déceler en permanence des menaces, opportunités, liées à votre marché et à votre image de marque.</List.Item>
            <List.Item>Outils de tracking, nous utilisons nos outils afin d'identifier le comportement de vos clients et élaborer une stratégie digitale adaptée à votre cible.</List.Item>
          </List>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">Segmentation et profiling</h2>
          <p className="justify">
            Que vous soyez une PME active dans plusieurs villes de Suisse Romande (Genève, Lausanne, Sion, Crans-Montana, Verbier...); Trade For World analyse vos données client et prospects sur l'ensemble de vos canaux de communication (site internet, base de donnés de l'entreprise, Facebook, Instagram, Twitter...) afin de vous aider à comprendre qui sont vos clients, quelles sont leurs habitudes de consommations et comment vous adresser à eux de manières à optimiser votre relation et vos
            chances de générer de futures ventes.
          </p>
          <p className="justify">Notre méthodologie et nos outils statistiques, nous permettent non seulement d'identifier vos clients actuels et potentiels, mais également de définir une "micro-segmentation" qui nous permet d’identifier des sous segments à fort potentiel d'achat.</p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">Analyse de données</h2>
          <p className="justify">
            Que vous soyez un hôtel de chaine, un boutique hôtel, une auberge de jeunesse, un restaurant, un bar, une patisserie, un boulanger, un concessionnaire automobile, une agence (régie) immobilière, une école, une étude d'avocat, un cabinet médical ou dentaire... vous avez tous la même problématique. Vous vous devez d'analyser vos données et être maître de vos outils digitaux. Selon l'expérience que nous avons avec nos clients aussi bien dans des stations de ski que dans de grandes
            villes suisses telle que Genève ou Lausanne, nous nous sommes rendus compte que ce n'était pas toujours le cas. C'est pourquoi en tant qu'agence web spécialisée dans les stratégie de marketing Inbound, nous avons développé une méthodologies et des outils d'analyse qui nous permettent d'extraire des données cruciales à la prise de décision et au développement de votre PME.
          </p>
        </GridColumn>
      </Grid>
    </Container>
  );
};

export default ServiceSubPage;
