import PagesHero from 'components/PagesHero';
import APPContext from 'config/context/APPContext';
import React from 'react';

const Skills = () => {
  const context = React.useContext(APPContext);
  React.useEffect(() => {
    context.setHero({ hero: <PagesHero title="Nos Compétence" className="contact" />, activeMenu: '/skills' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h1>skills</h1>;
};

export default Skills;
