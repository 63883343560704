import React from 'react';

import APPContext from './APPContext';
import { MPMReducer } from './MPMReducer';
import { SET_LANGUAGE, DEFAULT_LANGUAGE, SET_HERO } from 'config/Constants';
import HomeHero from 'components/HomeHero';

const GlobalState = (props) => {
  const [myState, dispatch] = React.useReducer(MPMReducer, {
    language: DEFAULT_LANGUAGE,
    hero: <HomeHero />,
    activeMenu: '/',
  });

  const setLanguage = (data) => {
    dispatch({ type: SET_LANGUAGE, data });
  };
  const setHero = (data) => {
    dispatch({ type: SET_HERO, data });
  };

  return (
    <APPContext.Provider
      value={{
        language: myState.language,
        setLanguage: setLanguage,
        hero: myState.hero,
        setHero: setHero,
        activeMenu: myState.activeMenu,
      }}
    >
      {props.children}
    </APPContext.Provider>
  );
};

export default GlobalState;
