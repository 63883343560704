import React from 'react'
import { Container, Grid, GridColumn, Image, List } from 'semantic-ui-react';
import img from 'assets/images/services-marketing-1.jpg'

const ServiceSubPage = () => {
    return (
        <Container >
            <Grid padded columns="2">
                <GridColumn mobile={16} largeScreen={8}>
                    <h1 className="uppercase sub-border" style={{margin: 0}}>Notre Mission</h1>
                    <p className="justify">
                    Aujourd'hui vous ne devez pas seulement être présent sur le web, vous devez également intéresser votre client. Pour cela vous devez produire du contenu qui a de la valeur et ainsi l'inciter à vouloir vous connaitre et voir l’offre ou le produit. C'est pour cela que nous vous proposons un service de marketing de contenu. Nous devenons votre community manager, nos créons votre contenu, organisons vos publications (réseaux sociaux, blogs), analysons et optimisons votre contenu existant (site internet, blogs, réseaux sociaux) afin d'optimiser et augmenter votre visibilité et positionnement sur internet. Ceci pour augmenter votre notoriété, gagner de nouveaux clients tout en fidélisant vos clients actuels.
                    </p>
                </GridColumn>
                <GridColumn verticalAlign="bottom" textAlign="right" mobile={16} largeScreen={8}>
                    <Image centered src={img}  />
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                <h2 className="uppercase">
                        <span className="color-teal">phase 1: </span> Analyse de contenu</h2>
                    <p className="justify">
                    L'analyse de contenu est la première phase de processus de gestion de contenu digital. Dans cette étape nous réalisons une étude poussée de la typologie de contenu (texte, format, graphisme, ton utilisé), de son impact (visibilité, notoriété, interactivité) sur vos outils digitaux (site internet, blog, réseaux sociaux...) et sur vos clients actuels et potentiels. 
                    </p>
                    <p className="justify">
                    Nous vérifions l'adéquation entre votre contenu physique et digital et  nous le comparons à vos principaux concurrents dans le but d'établir une matrice de performance.
                    </p>
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                <h2 className="uppercase">
                        <span className="color-teal">phase 2: </span> Optimisation de contenu</h2>
                    <p className="justify">
                    Suite à l’analyse de votre contenu, nous définissons des zones d’amélioration de votre contenu actuel. Effectivement, l'objectif de cette stratégie de contenu n’est pas de tout changer, voir de vous faire investir dans de nouveaux outils internet. 
                    </p>
                    <p className="justify">
                    Nous cherchons avant tout chose à optimiser l'existant afin de produire une meilleure performance sans pour autant engendrer nouveaux coûts. Par exemple, il n’est pas toujours obligatoire de changer de site internet, certaine fois une optimisation de son contenu et de son approche SEO sont suffisants.
                    </p>
                    
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                <h2 className="uppercase">
                        <span className="color-teal">phase 3: </span> Création de contenu</h2>
                    <p className="justify">
                    Suite à l'analyse préalable, nous réalisons votre contenu. Il est ciblé et destiné à une audience spécifique que nous avons déterminé selon la typologie de vos clients. La typologie du contenu que nous vous proposons est variée:
                     </p>
                     <List bulleted>
                        <List.Item> Textes (article de blogs, contenu web)</List.Item>
                        <List.Item> Image (adapté et optimisé pour la plateforme de destination (Site internet, blog, réseaux sociaux, Facebook - Instagram AD (carrousel), Display...</List.Item>
                        <List.Item>Courte vidéo pour réseaux sociaux principalement (nous ne faisons pas de montage vidéo ou de tournage), images animées...</List.Item>
                        <List.Item> Multilingue (Français - Anglais - Espagnol - Allemand)</List.Item>
                        <List.Item>Création et gestion de blogs</List.Item>
                    </List>
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                <h2 className="uppercase">
                        <span className="color-teal">phase 4: </span>  Publication de contenu & Suivi</h2>
                    <p className="justify">
                    La publication de contenu est la troisième phase du processus de gestion de contenu digital. Cette phase consiste à nous laisser la responsabilité de la publication de votre contenu digital. 
                    </p>
                    <p className="justify">
                    En tant que dirigeant d'entreprise, responsable vente ou communication, vous êtes très occupé et vous n'avez souvent pas le temps à consacré pour la publication de contenu sur vos outils digitaux. C'est pourquoi, nous vous proposons de nous charger de cette tâche contraignante mais indispensable aux succès d'une stratégie de marketing de contenu.

                    </p>
                    <p className="justify">
                    Nous nous chargeons également du suivi des résultats de votre stratégie de contenu marketing. Effectivement EZI Marketing possède des outils de suivi qui permettent de voir en temps réel l'impact de votre contenu sur vos publique cible.
                    </p>
                </GridColumn>
            </Grid>
        </Container>
    )
}

export default ServiceSubPage;