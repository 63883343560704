import React from 'react'
import { Container, Grid, GridColumn, Image, List } from 'semantic-ui-react';
import img from 'assets/images/services-outsourcing-1.jpg'
const ServiceSubPage = () => {
    return (
        <Container >
            <Grid padded columns="2">
                <GridColumn verticalAlign="bottom" textAlign="right" mobile={16} largeScreen={8}>
                    <Image centered src={img}  />
                </GridColumn>
                <GridColumn mobile={16} largeScreen={8}>
                    <h1 className="uppercase sub-border" style={{margin: 0}}>Notre Mission</h1>
                    <p className="justify">
                        Vous êtes une PME genevoise, vaudoise, neuchâteloise ou valaisanne et vous cherchez à optimiser votre marketing digital. Vous cherchez à optimiser votre marketing digital. Vous voulez être présent sur internet, optimiser le référencement de votre site internet, faire des posts réguliers sur Facebook, Instagram ou LinkedIn; gérer votre blog. Tout cela coûte du temps et demande un investissement conséquent (engager un spécialiste digital, diversifier le cahier des charges d'un employé (souvent débordé ou pas assez qualifié)). Avez-vous pensé à outsourcer ce service? Vous y gagnerez du temps, minimiserez vos dépenses tout en optimisant votre retour sur investissement.
                    </p>
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                    <h2 className="uppercase">
                        <span className="color-teal">phase 1: </span> Notre Mission</h2>
                    <p className="justify">
                        Afin d'avoir une image claire de vos besoins en matière de marketing digital, nous procédons tout d'abord à un audit de votre présence digitale, ainsi que de l'ensemble de vos outils numériques. Ceci nous permet de définir vos besoins et de mettre au point une stratégie de outsourcing digitale qui est basée sur des objectifs précis et appuyé par une méthodologie claire. Dans cette phase nous privilégions le contact avec vous et vos équipes afin de comprendre les enjeux liés à votre activité et de nous familiariser avec vos méthodes de travail. 
                     </p>
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                    <h2 className="uppercase">
                        <span className="color-teal">phase 2: </span> Nous définissions ensemble un cahier des charges digital</h2>
                    <p className="justify">
                        Suite à la phase d’analyse, nous vous proposons un cahier des charges de nos futures activités dans le cadre de l'outsourcing de votre marketing digital. Ce document est constitué de 2 parties
                     </p>
                     <List bulleted>
                        <List.Item>Le cadre d'actions qui définira le temps alloué à notre mandat. Le type d'activités que nous réaliserons (data mining, veille, optimisation, community management, SMA, SEO, LeadGen). Les objectifs visés.</List.Item>
                        <List.Item>Le cadre de collaboration qui définira le type de communication et les personnes clés</List.Item>
                    </List>
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                    <h2 className="uppercase">
                        <span className="color-teal">phase 3: </span> MISE EN PLACE.</h2>
                    <p className="justify">
                        La mise en place est la période durant laquelle nous implémentons l'ensemble des outils et procédures opérationnelles de votre stratégie de marketing digital. Nous créons le contenu nécessaire et lançons les premières actions en ligne afin de faire des tests et procéder aux calibrages nécessaires. 
                     </p>
                </GridColumn>
            </Grid>
            <Grid padded columns="1">
                <GridColumn mobile={16} largeScreen={16}>
                    <h2 className="uppercase">
                        <span className="color-teal">phase 4: </span> Actions, Réactions, Suivis</h2>
                    <p className="justify">
                        Suite au lancement de vos campagnes marketing, à la mise en place de nouveaux outils marketing et à la création de votre contenu; nous vous orientons nos activités vers le suivi et une analyse des résultats réguliers. Pour ce faire nous utilisons un extranet dédié à votre stratégie digitale. Cet outil est indispensable au suivi des résultats, il vous permet d'avoir accès à des tableaux de bord dynamiques, des rapports réguliers sur vos performances et des analyses détaillées. 
                     </p>
                </GridColumn>
            </Grid>
        </Container>
    )
}

export default ServiceSubPage;