import React from 'react';
import { DEFAULT_LANGUAGE } from '../Constants';

const APPContext = React.createContext({
    language: DEFAULT_LANGUAGE,
    setLanguage: () => { },
    hero : null,
    setHero: () => { },
    activeMenu: "/",
});

export default APPContext;