import { createMedia } from '@artsy/fresnel';
import { Container } from 'semantic-ui-react';
import Navigation from './Navigation';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});
const PagesHero = (props) => {
  return (
    <section className={`hero pages ${props.className}`}>
      <Container>
        <MediaContextProvider>
          <Media at="computer">
            <Navigation {...props} />
          </Media>
        </MediaContextProvider>
        <div className="content">{props.title}</div>
      </Container>
    </section>
  );
};

export default PagesHero;
