import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv'
import App from './App';
import { IntlProvider } from 'react-intl';
import { messages } from 'config/localization';
import 'semantic-ui-css/semantic.min.css';
import 'assets/main.css';
import APPContext from 'config/context/APPContext'
import GlobalState from 'config/context/GlobalState';

dotenv.config()

const GlobalApplication = () => {
  const context = React.useContext(APPContext);

  return (
    <GlobalState>
      <IntlProvider locale={context.language} messages={messages[context.language]} >
          <App />
        </IntlProvider>
    </GlobalState>
  )
}

ReactDOM.render(
  <GlobalApplication />,
  document.getElementById('root')
);
