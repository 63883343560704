import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import Navigation from './Navigation';
import Typing from 'react-typing-animation';
import { createMedia } from '@artsy/fresnel';
import { Link } from 'react-router-dom';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const HomeHero = (props) => {
  return (
    <section className="hero home">
      <Container>
        <MediaContextProvider>
          <Media at="computer">
            <Navigation {...props} />
          </Media>
        </MediaContextProvider>
        <div className="content">
          <Typing>
            <h1>
              NOUS SOMMES <b>TRADE FOR WORLD</b>
            </h1>
          </Typing>
          <h2>Nous sommes une agence de communication digital indépendante et fous de stratégie, de branding, de design, d’image et de vidéo. </h2>
          <h2>Nous créons pour vous tout un écosystèmes digital en adéquation avec votre histoire et votre objectif. </h2>
          <h2>Nous somme présent en Suisse, Espagne et Maroc. </h2>
          <Button as={Link} to="/contact-us" color="teal">
            Prenez contacte avec nous
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default HomeHero;
