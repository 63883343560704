import { SET_LANGUAGE, SET_HERO } from '../Constants';

const setLanguage = (data, state) => {
  return { ...state, language: data };
};

const setHero = (data, state) => {
  return { ...state, hero: data.hero, activeMenu: data.activeMenu };
};

export const MPMReducer = (state, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return setLanguage(action.data, state);
    case SET_HERO:
      return setHero(action.data, state);
    default:
      return state;
  }
};
