import imgLogo from 'assets/images/logo.png';
import { Button, Container, Form, Grid, GridColumn, Header, Icon, Image, List } from 'semantic-ui-react';
// eslint-disable-next-line no-unused-vars
const NewsLetter = () => {
  return (
    <section className="news-letter">
      <Container>
        <Grid stretched columns="2">
          <GridColumn mobile={16} largeScreen={8}>
            <Header>NEWSLETTER</Header>
            <Header.Content>Subscribe to our MailChimp newsletter and stay up to date with all events coming straight in your mailbox:</Header.Content>
            <Header.Content>* Personal data will be encrypted</Header.Content>
          </GridColumn>
          <GridColumn verticalAlign="middle" mobile={16} largeScreen={8}>
            <Form>
              <Form.Input
                name="email"
                placeholder="Votre adresse email"
                type="text"
                action={
                  <Button labelPosition="left" icon primary>
                    <Icon name="mail" />
                    S'ABONNER
                  </Button>
                }
              />
            </Form>
          </GridColumn>
        </Grid>
      </Container>
    </section>
  );
};
const Footer = () => {
  return (
    <footer>
      {/* <NewsLetter /> */}
      <section className="footer">
        <Container>
          <Grid columns="4">
            <GridColumn mobile={16} largeScreen={16}>
              <Grid columns="3" stackable>
                <GridColumn mobile={16}>
                  <Image src={imgLogo} size="small" />
                </GridColumn>
                <GridColumn mobile={16} largeScreen={5}>
                  <List>
                    <List.Item>
                      <List.Icon name="map marker alternate" />
                      <List.Content>
                        Chemin du Fau-Blan 2A <br /> 1009 Pully Suisse
                      </List.Content>
                    </List.Item>
                  </List>
                </GridColumn>
                <GridColumn mobile={16} largeScreen={5}>
                  <List>
                    <List.Item>
                      <List.Icon name="phone" />
                      <List.Content>(+41) 0225480150</List.Content>
                    </List.Item>
                  </List>
                </GridColumn>
                <GridColumn mobile={16} largeScreen={5}>
                  <List>
                    <List.Item>
                      <List.Icon name="mail" />
                      <List.Content>ch.info@trade-for-world.com</List.Content>
                    </List.Item>
                  </List>
                </GridColumn>
              </Grid>
            </GridColumn>
          </Grid>
        </Container>
      </section>
      <section className="last-footer">
        <Container>
          <span>Trade For World SARL © Tous droits réservés. </span>
          <div className="social">
            <Icon name="facebook f" />
            <Icon name="youtube play" />
            <Icon name="instagram" />
            <Icon name="twitter" />
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
