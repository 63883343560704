import imgContact from 'assets/images/contact-form.png';
import Map from 'components/Map';
import PagesHero from 'components/PagesHero';
import APPContext from 'config/context/APPContext';
import React from 'react';
import { Container, Form, Grid, GridColumn, Icon, Image } from 'semantic-ui-react';
const ContactUS = () => {
  const context = React.useContext(APPContext);
  React.useEffect(() => {
    context.setHero({ hero: <PagesHero title="Nous contacter" className="contact" />, activeMenu: '/contact-us' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData] = React.useState({
    firstname: { value: '', required: true, error: false, label: 'Prénom' },
    lastname: { value: '', required: true, error: false, label: 'Nom' },
    email: { value: '', required: true, error: false, label: 'Email' },
    application: 13,
    subject: { value: '', required: true, error: false, label: 'Sujet' },
    message: { value: '', required: true, error: false, label: 'Message' },
    organization: { value: '', required: false, error: false, label: 'Organisation' },
  });
  const onSubmitForm = () => {};
  return (
    <>
      <section className="contact-us">
        <Container>
          <div className="content">
            <Grid columns="2">
              <GridColumn mobile={16} largeScreen={9}>
                <h1>Écrivez-nous</h1>
                <p>Faites-nous savoir comment nous pouvons vous aider à relever un défi commercial ou engager une conversation pour mieux vous connaître.</p>
                <Form onSubmit={() => onSubmitForm()}>
                  <Form.Group widths="equal">
                    <Form.Input label={formData.firstname.label} value={formData.firstname.value} required={formData.firstname.required} error={formData.firstname.error} />
                    <Form.Input label={formData.lastname.label} value={formData.lastname.value} required={formData.lastname.required} error={formData.lastname.error} />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input label={formData.email.label} value={formData.email.value} required={formData.email.required} error={formData.email.error} />
                    <Form.Input label={formData.organization.label} value={formData.organization.value} required={formData.organization.required} error={formData.organization.error} />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input label={formData.subject.label} value={formData.subject.value} required={formData.subject.required} error={formData.subject.error} />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.TextArea rows="6" label={formData.message.label} value={formData.message.value} required={formData.message.required} error={formData.message.error} />
                  </Form.Group>
                  <Form.Button type="submit" icon labelPosition="right" color="teal">
                    <Icon name="chevron right" />
                    ENVOYER
                  </Form.Button>
                </Form>
                <br />
                <br />
              </GridColumn>
              <GridColumn verticalAlign="bottom" textAlign="right" mobile={16} largeScreen={7}>
                <Image src={imgContact} size="large" />
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </section>
      <Map />
    </>
  );
};

export default ContactUS;
