import logoIconOnlyBlue from 'assets/images/logo-icon-only-blue.png';
import GoogleMapReact from 'google-map-react';
import { Component } from 'react';
import { Container, Grid, GridColumn, List } from 'semantic-ui-react';

const Marker = (props) => <img src={logoIconOnlyBlue} width={30} alt="Trade For World SARL" />;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 46.523,
      lng: 6.6178,
    },
    zoom: 17,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <section className="map" style={{ height: '600px', width: '100%' }}>
        <div className="infos">
          <Container>
            <Grid columns="3" stackable>
              <GridColumn>
                <div className="contact">
                  <div className="content">
                    <h1>Suisse</h1>
                    <List>
                      <List.Item>
                        <span>Trade For World SARL </span>
                        <p>
                          Chemin du Fau-Blan 2A <br /> 1009 Pully Suisse
                        </p>
                      </List.Item>
                      <List.Item>
                        <span>Mail - ch.info@trade-for-world.com</span>
                      </List.Item>
                    </List>
                  </div>
                </div>
              </GridColumn>
              <GridColumn>
                <div className="contact">
                  <div className="content">
                    <h1>Espagne</h1>
                    <List>
                      <List.Item>
                        <span>Trade For World SARL </span>
                        <p>
                          Dionisio Ridruejo 1 <br /> 22007 Oviedo Espagna
                        </p>
                      </List.Item>
                      <List.Item>
                        <span>Mail - es.info@trade-for-world.com</span>
                      </List.Item>
                    </List>
                  </div>
                </div>
              </GridColumn>
              <GridColumn>
                <div className="contact">
                  <div className="content">
                    <h1>Maroc</h1>
                    <List>
                      <List.Item>
                        <span>Trade For World SARL </span>
                        <p>
                          Rue Michel Ange 15 <br /> 20000 Casablanca Maroc
                        </p>
                      </List.Item>
                      <List.Item>
                        <span>Mail - ma.info@trade-for-world.com</span>
                      </List.Item>
                    </List>
                  </div>
                </div>
              </GridColumn>
            </Grid>
          </Container>
        </div>
        <GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_JS_API_KEY }} defaultCenter={this.props.center} defaultZoom={this.props.zoom}>
          <Marker lat={46.5223498} lng={6.6177365} />
        </GoogleMapReact>
      </section>
    );
  }
}

export default SimpleMap;
