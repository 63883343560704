/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PagesHero from 'components/PagesHero';
import APPContext from 'config/context/APPContext';
import Consulting from 'pages/services/Consulting';
import ContentMarketing from 'pages/services/ContentMarketing';
import EReputation from 'pages/services/EReputation';
import OutSourcing from 'pages/services/OutSourcing';
import Strategy from 'pages/services/Strategy';
import { useParams } from 'react-router-dom';
import { Container, Tab } from 'semantic-ui-react';
const panes = [
  {
    menuItem: 'e-reputation et e-surveillance',
    url: 'e-reputation',
  },
  {
    menuItem: 'content marketing',
    url: 'content-marketing',
  },
  {
    menuItem: 'stratégie',
    url: 'strategy',
  },
  {
    menuItem: 'consulting',
    url: 'consulting',
  },
  {
    menuItem: 'outsourcing',
    url: 'outsourcing',
  },
];
const Services = (props) => {
  const context = React.useContext(APPContext);
  React.useEffect(() => {
    context.setHero({ hero: <PagesHero title="NOS SERVICES" className="services" />, activeMenu: '/services' });
  }, []);

  const { id } = useParams();
  const pathIndex = panes.findIndex((elem) => elem.url === id);
  console.log('pathIndex', id, pathIndex);
  const [activeIndex, setActiveIndex] = React.useState(pathIndex === -1 ? 0 : pathIndex);

  const handleTabChange = (e, { activeIndex }) => {
    let path = panes[activeIndex].url;
    setActiveIndex(activeIndex);
    props.history.push(`/services/${path}`);
  };

  return (
    <section className="block-services">
      <div className="header">
        <Container>
          <Tab activeIndex={activeIndex} menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={handleTabChange} />
        </Container>
      </div>
      <div className="content">
        {activeIndex === 0 && <EReputation />}
        {activeIndex === 1 && <ContentMarketing />}
        {activeIndex === 2 && <Strategy />}
        {activeIndex === 3 && <Consulting />}
        {activeIndex === 4 && <OutSourcing />}
      </div>
    </section>
  );
};

export default Services;
