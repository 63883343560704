import imgApropos from 'assets/images/home-apropos.jpg';
import logoConsulting from 'assets/images/home-consulting-icon.png';
import logoContentMarketing from 'assets/images/home-contentmarketing-icon.png';
import logoEReputation from 'assets/images/home-ereputation-icon.png';
import logoOutsourcing from 'assets/images/home-outsourcing-icon.png';
import logoStrategy from 'assets/images/home-strategy-icon.png';
import HomeHero from 'components/HomeHero';
import Map from 'components/Map';
import APPContext from 'config/context/APPContext';
import React from 'react';
import { Container, Grid, GridColumn, Image } from 'semantic-ui-react';

const Home = (props) => {
  const context = React.useContext(APPContext);
  React.useEffect(() => {
    context.setHero({ hero: <HomeHero />, activeMenu: '/' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="block apropos bg-dark-blue">
        <Container>
          <div className="header color-white">
            <h1>à propos de nous</h1>
          </div>
          <div className="content">
            <Grid columns="2">
              <GridColumn mobile={16} largeScreen={8}>
                <h1 className="color-white">NOTRE AGENCE</h1>
                <p className="color-white justify">
                  Trade For World est une jeune agence née en décembre 2000 dans une ville helvétique plutôt tranquille Lausanne ou il fait bon y vivre, Au fil de l’histoire de Trade For World, la communication digitale a changé plusieurs fois de visage. En 2012, alors que nous étions des pionniers sur la scène locale du marketing digital, nous convainquions nos clients d’adopter une présence sur les réseaux sociaux. Depuis, nous avons évolué plusieurs fois, que ce soit du point de vue de nos
                  services, ou de notre organisation interne.
                </p>
                <p className="color-white justify">
                  Le secteur dans lequel nous nous inscrivons nécessite de s’adapter au quotidien. En 2015 nous avons senti le besoin d’évoluer une fois de plus. Dans l’optique de continuer d’offrir les meilleurs services possibles à nos clients, ça n’était plus suffisant d’avoir, à l’intérieur de nos murs, quelques managers du marketing digital et quelques développeurs web, nous avions besoin de créer une entité à part, composée d’une équipe d’experts, entièrement dévolue à un seul objectif :
                  aider nos clients à se développer.{' '}
                </p>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={8}>
                <Image src={imgApropos} />
              </GridColumn>
              <GridColumn mobile={16}>
                <p className="color-white justify">
                  Au final, la communication digitale n’est pas une affaire de jolies photos sur Instagram ou FaceBook, c’est un outil complexe et puissant, à mettre au service d’un objectif : le développement d’une entreprise et de son chiffre d’affaire. Et c’est exactement ce que nous faisons chez Trade For World, nous nous focalisons sur les objectifs de nos clients, et nous leur permettons d’arriver à maturité et de se maintenir au milieu des nouvelles formes de compétitivité digitale. Nous
                  analysons votre entreprise, nous définissons une stratégie
                </p>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </section>
      <section className="block services">
        <Container>
          <div className="header ">
            <h1>Nos services</h1>
          </div>
          <div className="subheader "></div>
          <div className="content">
            <Grid columns="3">
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>
                      e-Reputation et <br /> e-Surveillance{' '}
                    </h1>
                    <Image src={logoEReputation} size="tiny" />
                  </div>
                  <div className="box-content">
                    Que vous soyez un établissement hôtelier, un restaurant, une régie immobilière, une école, un salon de coiffure ou cabinet dentaire; vous avez tous besoin de contrôler votre réputation en ligne (e-reputation). ... <a href="/services/e-reputation">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>
                      Content <br /> Marketing{' '}
                    </h1>
                    <Image src={logoContentMarketing} size="tiny" />
                  </div>
                  <div className="box-content">
                    Aujourd'hui vous ne devez pas seulement être présent sur le web, vous devez également intéresser votre client. Pour cela vous devez produire du contenu qui a de la valeur et ainsi l'inciter à vouloir ... <a href="/services/content-marketing">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>Stratégie </h1>
                    <Image src={logoStrategy} size="tiny" />
                  </div>
                  <div className="box-content">
                    Vous êtes une PME genevoise, vaudoise, neuchâteloise ou valaisanne et vous pensez qu'il est l'heure de moderniser votre marketing digital et de passer à la vitesse supérieure. ... <a href="/services/strategy">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>Consulting </h1>
                    <Image src={logoConsulting} size="tiny" />
                  </div>
                  <div className="box-content">
                    Si vous souhaitez des conseils quant à votre stratégie de marketing digital ou si vous avez besoins d’analyses concernant votre image de marque, la performance de vos actions en ligne, l'implémentation de nouveaux outils (CRM, ERP...). ... <a href="/services/consulting">continuer</a>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} largeScreen={5}>
                <div className="box">
                  <div className="box-header">
                    <h1>Outsourcing </h1>
                    <Image src={logoOutsourcing} size="tiny" />
                  </div>
                  <div className="box-content">
                    Vous êtes une PME genevoise, vaudoise, neuchâteloise ou valaisanne et vous cherchez à optimiser votre marketing digital. Vous cherchez à optimiser votre marketing digital. Vous voulez ... <a href="/services/outsourcing">continuer</a>
                  </div>
                </div>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </section>

      {/* <section className="block savoirefaire bg-dark-blue">
                <Container>
                <div className="header color-white">
                    <h1>nOTRE SAVOIR FAIRE</h1>
                </div>
                <div className="subheader color-white">
                    LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT, SED DO EIUSMOD TEMPOR INCIDIDUNT
                </div>
                <div className="content">
                    <Grid columns="4">
                        <GridColumn mobile={8} largeScreen={4}>
                            <div className="progress-box">
                                <div className="progress-circle">
                                    <h1 className="color-purple-light">84%</h1>
                                    <Circle percent="84" strokeWidth="2" trailWidth="2" trailColor="#50506a" strokeColor="#8781bd" />
                                </div>
                                <div className="progress-title">
                                    Stratégie
                                </div>
                            </div>
                        </GridColumn >
                        <GridColumn mobile={8} largeScreen={4}>
                            <div className="progress-box">
                                <div className="progress-circle">
                                    <h1 className="color-red-light">50%</h1>
                                    <Circle percent="50" strokeWidth="2" trailWidth="2" trailColor="#5f4a5d" strokeColor="#f15364" />
                                </div>
                                <div className="progress-title">
                                    Content Marketing
                                </div>
                            </div>
                        </GridColumn>
                        <GridColumn mobile={8} largeScreen={4}>
                            <div className="progress-box">
                                <div className="progress-circle">
                                    <h1 className="color-yellow-light">78%</h1>
                                    <Circle percent="78" strokeWidth="2" trailWidth="2" trailColor="#62545b" strokeColor="#ff9955" />
                                </div>
                                <div className="progress-title">
                                Outsourcing
                                </div>
                            </div>
                        </GridColumn>
                        <GridColumn mobile={8} largeScreen={4}>
                            <div className="progress-box">
                                <div className="progress-circle">
                                    <h1 className="color-green-light">86%</h1>
                                    <Circle percent="86" strokeWidth="2" trailWidth="2" trailColor="#4e5c6c" strokeColor="#7accc8" />
                                </div>
                                <div className="progress-title">
                                Consulting
                                </div>
                            </div>
                        </GridColumn>
                    </Grid>
                </div>
                </Container>
            </section> */}
      <Map />
    </>
  );
};

export default Home;
