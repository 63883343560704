import img from 'assets/images/services-strategy-1.jpg';
import { Container, Grid, GridColumn, Image } from 'semantic-ui-react';

const ServiceSubPage = () => {
  return (
    <Container>
      <Grid padded columns="2">
        <GridColumn verticalAlign="bottom" textAlign="right" mobile={16} largeScreen={8}>
          <Image centered src={img} />
        </GridColumn>
        <GridColumn mobile={16} largeScreen={8}>
          <h1 className="uppercase sub-border" style={{ margin: 0 }}>
            Stratégies
          </h1>
          <p className="justify">
            Vous êtes une PME genevoise, vaudoise, neuchâteloise ou valaisanne et vous pensez qu'il est l'heure de moderniser votre marketing digital et de passer à la vitesse supérieure. EZI Marketing est un cabinet de conseils en stratégies digitales spécialisé en Inbound marketing. Nous proposons de mettre en place votre stratégie de marketing digital grâce à des solutions créatives basées sur l'étude de votre clients, ces caractéristiques et son comportement. Le but est d'optimiser vos
            processus d'acquisition et de créer une relation solide sur l'ensemble de vos canaux de communication en ligne (site internet, réseaux sociaux, blogs, plateformes internet...).
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">
            <span className="color-teal">phase 1: </span> Analyse et segmentation
          </h2>
          <p className="justify">
            Afin d'avoir une image claire de votre entreprise d'un point de vu digital, nous procédons à un audit de l'ensemble de vos canaux de communication digitaux (site internet, réseaux sociaux, blog...). Grâce à notre outil de veille marketing, nous procédons à un scanning du web afin de définir l'impact de votre image en ligne et de définir le positionnement de votre entreprise face à ses principaux concurrents. Ensemble, nous définissons votre ADN de marque puis nous établissons une
            segmentation de votre clientèle actuelle, de même que nous définissons les caractéristiques de votre audience digitale. Tout au long de notre analyse nous vous donnons accès aux résultats et rapports via un extranet dédié.
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">
            <span className="color-teal">phase 2: </span> Définissions ensemble votre future stratégie digitale
          </h2>
          <p className="justify">
            Suite à la phase d’analyse, nous pouvons définir des objectifs stratégiques que nous validons avec vous, ainsi que des actions correctives visant tout d'abord à optimiser vos outils marketing . Ensuite nous élaborons une stratégie de communication via un plan d’actions stratégiques regroupant les budgets et le timing sous forme d’un calendrier mensuel sur une année. L’ensemble de notre méthodologie s’appuie sur un processus d’Inbound marketing. Selon votre niveau de digitalisation
            actuel, et l'impact des actions d'optimisation proposée préalablement; nous définissions une stratégie intégrée visant à augmenter votre visibilité, votre notoriété, de même que l'ensemble de vos cônes de conversion.
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">
            <span className="color-teal">phase 3: </span> Lancement de votre stratégie intégrée
          </h2>
          <p className="justify">
            Nous nous chargeons du lancement des plans d’actions et de communication. Nous procédons à la création et au lancement des campagnes aussi bien sur les réseaux sociaux que sur Google. Nous nous chargeons de la création du contenu indispensable, et mettons en place les processus de gestion de communauté et de marketing automation. Nous vous donnons un accès à un CRM complet (ventes, automatisation et gestion de contenu dynamique) afin que vous puissiez avoir 100% de contrôle sur les
            actions de communication entreprises. Nous organisons des séances de Coaching afin de familiarisé votre personnel à ces nouveaux outils. Après une durée définie au préalable, nous établissons un état des lieux et présentons des possibilités d’optimisation.
          </p>
        </GridColumn>
      </Grid>
      <Grid padded columns="1">
        <GridColumn mobile={16} largeScreen={16}>
          <h2 className="uppercase">
            <span className="color-teal">phase 4: </span> Suivi des résultats et optimisation
          </h2>
          <p className="justify">
            Suite au lancement des vos campagnes marketing, à la mise en place de nouveaux outils marketing et à la création de votre contenu; nous vous proposons un suivi et une analyse des résultats réguliers. Pour ce faire nous utilisons un extranet dédié à votre stratégie digitale. Cet outil est indispensable au suivi des résultats, il vous permet d'avoir accès à des tableaux de bord dynamiques, des rapports réguliers sur vos performances et des analyses détaillées de votre visibilité et
            notoriété. En effet une stratégie de communication digitale doit être constamment analysée et remise en question.
          </p>
        </GridColumn>
      </Grid>
    </Container>
  );
};

export default ServiceSubPage;
