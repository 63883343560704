import React from 'react';
import { Image, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import APPContext from 'config/context/APPContext';

const Navigation = (props) => {
  const context = React.useContext(APPContext);
  return (
    <Menu secondary className="navigation">
      <Menu.Item>
        <Image src={logo} size="small" />
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item as={Link} to="/" name="Accueil" active={context.activeMenu === '/'} />
        <Menu.Item as={Link} to="/services/e-reputation" name="nos services" active={context.activeMenu === '/services'} />
        <Menu.Item as={Link} to="/marketing-inbound" name="marketing entrant" active={context.activeMenu === '/marketing-inbound'} />
        <Menu.Item as={Link} to="/contact-us" name="Nous contacter" active={context.activeMenu === '/contact-us'} />
      </Menu.Menu>
    </Menu>
  );
};

export default Navigation;
