/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel';
import logo from 'assets/images/logo.png';
import APPContext from 'config/context/APPContext';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon, Image, Menu, Segment, Sidebar, Visibility } from 'semantic-ui-react';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};
  static contextType = APPContext;

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;

    return (
      <Media greaterThan="mobile">
        <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
          {this.context.hero}
        </Visibility>

        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};
  static contextType = APPContext;
  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar as={Menu} animation="overlay" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
            <Menu.Item as={Link} to="/" name="Accueil" active={this.context.activeMenu === '/'} />
            <Menu.Item as={Link} to="/services/e-reputation" name="nos services" active={this.context.activeMenu === '/services'} />
            {/* <Menu.Item as={Link} to="/skills"
                name='nos compétences'
                active={this.context.activeMenu === '/skills'}
                /> */}
            <Menu.Item as={Link} to="/marketing-inbound" name="marketing entrant" active={this.context.activeMenu === '/marketing-inbound'} />
            {/* <Menu.Item as={Link} to="/blog"
                name='blog'
                active={this.context.activeMenu === '/blog'}
                /> */}
            <Menu.Item as={Link} to="/contact-us" name="Nous contacter" active={this.context.activeMenu === '/contact-us'} />
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment inverted textAlign="center" style={{ padding: '1em 0em' }} vertical>
              <Container>
                <Menu inverted pointing secondary size="large">
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                  <Menu.Item position="right" className="p-8">
                    <Image src={logo} size="tiny" wrapped />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
            {this.context.hero}
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = ({ children }) => <ResponsiveContainer>{children}</ResponsiveContainer>;

export default HomepageLayout;
