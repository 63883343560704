import React from 'react'
import Home from 'pages/Home';
import MentionLegales from 'pages/MentionsLegales';
import ConditionUtilisation from 'pages/ConditionsUtilisations';
import Services from 'pages/services'
import Skills from 'pages/Skills'
import MarketingInbound from 'pages/MarketingInbound'
import Blog from 'pages/Blog'
import ContactUs from 'pages/ContactUs'
import Footer from 'components/Footer'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from 'components/Layout'

function App() {

  

  return (
    <div className="application">
        <BrowserRouter>
          <Layout>
            <Switch>
                  <Route path="/condition-utilisation" component={ConditionUtilisation} />
                  <Route path="/mentions-legales" component={MentionLegales} />
                  <Route path="/services/:id" component={Services} />
                  <Route path="/skills" component={Skills} />
                  <Route path="/marketing-inbound" component={MarketingInbound} />
                  <Route path="/blog" component={Blog} />
                  <Route path="/contact-us" component={ContactUs} />
                  <Route path="" component={Home} />
              </Switch>
              <Footer />
          </Layout>
        </BrowserRouter>
      
    </div>
  );
}

export default App;
